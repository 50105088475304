<template>
  <router-view></router-view>
</template>

<script>import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'LayoutBlank'
})
</script>

<style>

</style>